import React from "react";
import { Id } from "storefront/lib/Id";
import { GrailedAPILightListing } from "storefront/Listing/GrailedAPILightListing";
import { TrackingItemProperties, ItemType } from "storefront/Contentful/types";
import { ProductClickedFrom, PageType } from "storefront/Analytics/Event";
import { From } from "storefront/Analytics/EventCreators/productAddedToWishlist";
import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import Carousel from "../Carousel";
import ListingItem from "../ListingItem";

type TrackClick = (properties: TrackingItemProperties) => () => void;

type Props = {
  listings: Array<GrailedAPILightListing | AlgoliaListing>;
  trackClick?: TrackClick;
  numOfItemsToShow: number;
  moduleName?: string;
  moduleType?: string;
  pageType: PageType;
  pageTypeIdentifier?: Id;
  pageTypeName?: string;
  from?: From;
};

const trackCallback = (
  listing: GrailedAPILightListing | AlgoliaListing,
  index: number,
  trackClick: TrackClick,
): (() => void) => {
  const itemType: ItemType = "listing card";

  const properties = {
    itemType,
    itemPosition: index,
    itemName: `${listing.designerNames} ${listing.title}`,
    itemNameContentful: `${listing.designerNames} ${listing.title}`,
    imageUrl: listing.coverPhoto.url,
  };

  return trackClick(properties);
};

const ListingCarousel = ({
  listings,
  numOfItemsToShow,
  trackClick,
  moduleName,
  moduleType,
  pageType,
  pageTypeIdentifier,
  pageTypeName,
  from,
}: Props) => {
  const productClickedFrom: ProductClickedFrom = {
    component: "module",
    moduleName,
    moduleType,
    pageType,
    pageTypeIdentifier,
    pageTypeName,
  };

  return (
    <Carousel
      baseClassName="ListingCarousel"
      itemsToShow={numOfItemsToShow}
      carouselItems={listings.map((l, i) => (
        <ListingItem
          key={l.id}
          listing={l}
          additionalTrack={
            trackClick ? trackCallback(l, i, trackClick) : undefined
          }
          from={from}
          productClickedFrom={productClickedFrom}
          displayStyle="carousel"
        />
      ))}
      listingPageCarousel
    />
  );
};

export default ListingCarousel;
