import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

/**
 * @description
 * A React Hook to run a callback whenever a component comes into view on the screen. It will only
 * trigger once, so if the component goes out of and back into view then it won't trigger again.
 *
 * Example use: const onViewRef = useCallbackOnView(trackViewed);
 *
 * Then attach the ref to the component as a prop; ref={onViewRef}
 */
const useCallbackOnView = (
  callback: () => void,
  triggerOnce = true,
  threshold = 0,
): ((node?: Element | null) => void) => {
  const { ref, inView } = useInView({
    triggerOnce,
    threshold,
  });

  useEffect(() => {
    if (!inView) {
      return;
    }

    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return ref;
};

export default useCallbackOnView;
